.Homepage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin-left: auto;
    margin-top: auto;
}

.Homepage-logo {
    width: 50%;
}

.Homepage-login-button {
    width: 300px;
    height: 50px;
    padding: 0 16px;
    margin-top: 24px;
    background: #c9c9c9;
    color: #3f3f3f;
    font-weight: 900;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 3px;
}
