.dashboard-container {
	background-color: #ce949400;
	display: grid;
	min-height: 100vh;
	position: relative;
	padding-bottom: 60px;
}

.dashboard-content-container {
	/* border: 2px solid #767776; */
	height: 100%;
	position: relative;
}

.dashboard-content-row {
	position: relative;
	margin: 16px;
}
.sidebar {
	position: sticky;
}

.dashboard-content-row::before,
.dashboard-content-row::after {
	display: block;
	content: '';
	clear: both;
}
.nav-link {
	text-decoration: none;
}

.nav-text {
	color: white;
	font-size: 80%;
}

.nav-button {
	color: white;
	padding-top: 0;
	padding-bottom: 0;
}
.nav-button:hover {
	color: #32db60;
}

.active-link .nav-text {
	color: #00ac2e;
}

.active-link .nav-button {
	color: #00ac2e;
}

.hover-link:hover .nav-button {
	color: #32db60;
}

.hover-link:hover .nav-text {
	color: #32db60;
}
