.chart-container {
	// width: 821px !important;
	height: 328px;
}

.filter-container {
	height: 125px;
	overflow: auto;
}

.chart-container-without-filters {
	height: 420px;
}

.screen-expand-chart {
	min-height: 497px;
}

.map-chart {
	min-height: 455px;
}

.feature-weight-chart-wrapper {
	position: relative;
	// height: 497px;
	height: 447px;
	overflow-y: scroll;
}

.feature-weight-chart-area-wrapper {
	height: 2000px;
}

.feature-weight-x-axis-wrapper {
	position: relative;
	height: 40px;
	overflow: hidden;
}

.feature-weight-x-axis-area-wrapper {
	height: 2000px;
}

.feature-weight-x-axis-label-wrapper {
	position: relative;
	width: min-content;
	left: 60.5%;
	height: 20px;
}
