.legend-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	.legend-bar-container {
		flex-grow: 1;
		display: flex;
		list-style-type: none;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
		.legend-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: nowrap;
			width: max-content;
			margin: 4px 4px;
			

			.legend-color-bar {
				width: 42px;
				height: 12px;
				margin: 0px 4px;
				display: inline-block;
			}
			.legend-title {
				flex-wrap: nowrap;
				font-size: 12px;
			}
		}
	}
	margin: 12px;
}

.strikethrough {
	text-decoration: line-through;
}
